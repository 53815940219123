export default {
	"Bank card": "Bank card",
	"Card number": "Card number",
    "Expiration date": "Expiration date",
    "Code": "Code",
    "CARD": "1234 1234 1234 1234",
    "MM": "MM",
    "YY": "YY",
    "MM/YY": "MM/YY",
    "CVC": "CVC",
    "Pay": "Pay",
    "Pending payment": "Pending payment",

    "Pay2": "Pay",
    "Total": "Total",
    "Email (to receive a check)": "Email (to receive a check)",

    "SBP": "SBP",
    "One-time payment": "One-time payment. Select the “Bank Card“ option for subscription",
    "Subscription": "Subscription. You can cancel at any time",

    "Input email": "Input email",
    "Incorrect email format": "Incorrect email format",

    "Payment in processing": "Payment in processing",
    "Payment in processing description": "You can close the page and return to the bot",
    "Payment has been successfully completed": "Payment has been successfully completed",

    "Loading": "Loading",
    "error.Cancel": "Cenceled. Sorry, something went wrong.",
    "error.Unknown": "Sorry, something went wrong.",
    "error.Technical issues": "Technical issues.",
};
