export default {
    "Bank card": "Банковская карта",
	"Card number": "Номер карты",
    "Expiration date": "Срок действия",
    "Code": "Код",
    "CARD": "1234 1234 1234 1234",
    "MM": "ММ",
    "YY": "ГГ",
    "MM/YY": "ММ/ГГ",
    "CVC": "CVC",
    "Pay": "Заплатить",
    "Pending payment": "Ожидается оплата",

    "Pay2": "Оплатить",
    "Total": "Всего",
    "Email (to receive a check)": "Электронная почта (для получения чека)",

    "SBP": "SBP",
    "One-time payment": "Одноразовый платеж. Для подписки выберите вариант “Банковская карта”",
    "Subscription": "Подписка. Можно отменить в любой момент",

    "Input email": "Введите почту",
    "Incorrect email format": "Неправильный формат почты",

    "Payment in processing": "Платеж в обработке",
    "Payment in processing description": "Можно закрыть страницу и вернуться в бота",
    "Payment has been successfully completed": "Покупка успешно совершена",

    "Loading": "Загрузка",
    "error.Cancel": "Отменено. Извините, что-то пошло не так.",
    "error.Unknown": "Извините, что-то пошло не так.",
    "error.Technical issues": "Технические неполадки.",
}