import { nextTick } from "vue";
import ShellService from "./ShellService";

export default class TelegramService extends ShellService {
	constructor() {
		super();

		this._isInited = false;
		this._telegram = null;
	}

	async init() {
		this._telegram = this._telegram || window.Telegram || null;

		await new Promise((resolve, reject) => {
			const script = document.createElement("script");
			const timeoutId = setTimeout(() => reject(), 3000);

			script.setAttribute("src", "https://telegram.org/js/telegram-web-app.js");
			script.setAttribute("rel", "modulepreload");
			script.addEventListener("load", async () => {
				this._telegram = window.Telegram || null;

				clearTimeout(timeoutId);

				// Я хз, но похоже иногда оно не успевает подгружаться
				await nextTick();

				this._app.ready();
				this._app.expand();
				this._app.enableClosingConfirmation();

				this._app.onEvent("viewportChanged", this._expand);

				this.initBackButton();

				console.log("TelegramService", { self: this });

				resolve(this);
			});

			document.head.appendChild(script);
		});

		this._isInited = true;
	}

	get _app() {
		return (this._telegram && this._telegram.WebApp) || {};
	}

	get isInited() {
		return this._isInited;
	}

	get token() {
		if (!this._app.initDataUnsafe?.query_id) {
			const initParams = this._telegram.WebView.initParams;
			if (initParams && initParams.user) {
				/**
				 * @TODO Непонятно, почему у юзеров не подтягивается токен,
				 * временное решение такое - хардкод того, что никак не получить из телеграм токена
				 */
				const QUERY_ID = "AAFxtU8UAAAAAHG1TxRxEODR";
				const userParams = initParams.user;
				const authDate = initParams.auth_date;
				const hash = initParams.hash;

				return `query_id=${QUERY_ID}&user=${userParams}&${authDate}&${hash}`;
			}
		}

		return this._app.initData || "";
	}

	get theme() {
		return this._app.colorScheme;
	}

	get themeParams() {
		return {
			bg_color: this._app.themeParams?.bg_color || "#0D182F",
			button_color: this._app.themeParams?.button_color || "#00BF96",
			button_text_color: this._app.themeParams?.button_text_color || "#FFFFFF",
			hint_color: this._app.themeParams?.hint_color || "#366093",
			secondary_bg_color: this._app.themeParams?.secondary_bg_color || "#244166",
			text_color: this._app.themeParams?.text_color || "#DBDCE0",
		};
	}

	handleRouteChanging() {
		const canBack = !!window.history.state.back;

		if (canBack && this._app.BackButton) {
			this._app.BackButton.show();
		} else {
			this._app.BackButton.hide();
		}
	}

	openLink(url) {
		window.open(url, "_self")
		// this._app.openLink(url);
	}

	close() {
		this._app.close();
	}

	initBackButton() {
		const onClick = () => {
			history.go(-1);
		};

		this._app.BackButton.isVisible = false;
		this._app.BackButton.onClick(() => onClick());
	}

	_expand() {
		if (!this._app.isExpanded) {
			this._app.expand();
		}
	}
}
