export default class ShellService {
	async init() {
		throw "Нет init";
	}

	get isInited() {
		throw "Нет isInited";
	}

	get token() {
		throw "Нет token";
	}

	get theme() {
		throw "Нет theme";
	}

	get themeParams() {
		throw "Нет themeParams";
	}

	handleRouteChanging() {
		throw "Нет handleRouteChanging";
	}

	openLink(url) {
		throw "Нет openLink";
	}
}
