import { nextTick } from "vue";
import ShellService from "./ShellService";

export default class WebService extends ShellService {
	constructor() {
		super();

		this._isInited = false;
		this._theme = null;
		this._themeParams = null;
	}

	async init({ theme, themeParams }) {
		this._theme = theme;
		this._themeParams = themeParams;

		this._isInited = true;
	}

	get isInited() {
		return this._isInited;
	}

	get token() {
		return "";
	}

	get theme() {
		return this._theme || "light";
	}

	get themeParams() {
		return {
			bg_color: this._themeParams.bg_color || (this.theme === "light" ? "#0D182F" : "#1C1C1C"),
			button_color: this._themeParams.button_color || (this.theme === "light" ? "#007AFF" : "#3F87F6"),
			button_text_color: this._themeParams.button_text_color || (this.theme === "light" ? "#FFFFFF" : "#FFFFFF"),
			hint_color: this._themeParams.hint_color || (this.theme === "light" ? "#366093" : "#366093"),
			secondary_bg_color: this._themeParams.secondary_bg_color || (this.theme === "light" ? "#244166" : "#000000"),
			text_color: this._themeParams.text_color || (this.theme === "light" ? "#DBDCE0" : "#FFFFFF"),
		};
	}

	handleRouteChanging() {
		//
	}

	openLink(url) {
		window.parent.postMessage(
			{
				title: "OPEN_LINK",
				data: url,
			},
			"*",
		);
	}

	close() {
		//
	}
}
