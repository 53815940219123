<template>
	<router-view v-if="isRouteHandled" :localization="localization"/>
</template>

<script setup>
import {onBeforeMount, onMounted, shallowReactive, computed, watch, ref} from 'vue'
import TelegramService from "./shells/TelegramService"
import WebService from "./shells/WebService"
import {useRoute, useRouter} from "vue-router";
import {app} from "./main"
import localizationMap from "./localization/_index.ts"

const route = useRoute();
const router = useRouter()

const shellService = ref(null);
const localization = ref({})
const isRouteHandled = ref(false)

watch(
	() => shellService.value?.isInited,
	(to) => {
		if (shellService.value?.isInited) {
			refreshTheme()
		}
	},
	{deep: true},
);

watch(
	() => [route, shellService.value?.isInited],
	(to) => {
		if (shellService.value?.isInited) {
			shellService.value.handleRouteChanging();
		}
	},
	{flush: "pre", deep: true},
);

/**
 * При инициализации приложения забираем в локалсторадж-хранилище
 * все параметры, которые подали в ссылке
 *
 * Это для надежности, чтобы не прокидывать их каждый раз из страницы в страницу
 * (а то они могут потеряться, а тут уж железобетонно зафиксировались и можно
 * на любой странице получить их)
 *
 * Параметры через тире, потому что они потом прокидываются в бэк в хедере,
 * а хедер пропускает только такую нотацию пропсов
 */
router.isReady().then(() => {
	const query = route.query
	console.log('isReady', {query})

	const isIframe = query['is-iframe']
	localStorage.setItem("pay.isIframe", isIframe)

	const languageCode = query['language-code'] || "en"
	localStorage.setItem("pay.languageCode", languageCode)

	const theme = query['theme']
	localStorage.setItem("pay.theme", theme)

	const themeParams = query['theme-params'] // ? JSON.parse(query.theme_params) : {}
	localStorage.setItem("pay.themeParams", themeParams)

	const amount = query['amount']
	localStorage.setItem("pay.amount", amount)

	const products = query['products']
	localStorage.setItem('pay.products', products)

	const email = query['email']
	localStorage.setItem("pay.email", email)

	const currencyCode = query['currency-code']
	localStorage.setItem("pay.currencyCode", currencyCode)

	const productTokenAutoPay = query['product-token-auto-pay']
	localStorage.setItem("pay.productTokenAutoPay", productTokenAutoPay)

	const productTokenSinglePay = query['product-token-single-pay']
	localStorage.setItem("pay.productTokenSinglePay", productTokenSinglePay)

	isRouteHandled.value = true

	initLocalization()
	initTheme()
})

onMounted(() => {
	document.body.onclick = function () {
	};
	document.body.firstElementChild.tabIndex = 1
})

const refreshTheme = () => {
	const THEME_LIGHT = "light"
	const THEME_DARK = "dark"

	const htmlElement = document.querySelector("html")

	fetchCssVariables()

	const colorScheme = shellService.value?.theme || THEME_LIGHT
	if (colorScheme === THEME_LIGHT) {
		htmlElement.setAttribute("theme", THEME_LIGHT)
	} else {
		htmlElement.setAttribute("theme", THEME_DARK)
	}
}

const fetchCssVariables = () => {
	const normalizedCssVariablesArray = [];

	Object.entries(shellService.value?.themeParams || {}).forEach(([key, prop]) => {
		const normalizedKey = `--theme_${key}`;
		normalizedCssVariablesArray.push([normalizedKey, prop]);
	});

	const headTag = document.getElementsByTagName("head")[0];
	const styleTag = document.createElement("style");

	styleTag.innerHTML = `
  :root {
      ${normalizedCssVariablesArray.map((v) => `${v[0]}: ${v[1]};`).join("")}
  }
  `;
	headTag.appendChild(styleTag);
}

const initLocalization = () => {
	const languageCode = localStorage.getItem("pay.languageCode")
	localization.value = localizationMap[languageCode] || {}
}

/**
 * Почему обернули shellService в shallowReactive:
 * https://stackoverflow.com/questions/67894487/vue-3-reactivity-not-triggered-from-inside-a-class-instance
 */
const initTheme = () => {
	const isIframe = !!Number(localStorage.getItem("pay.isIframe") || 0)
	const theme = localStorage.getItem("pay.theme")
	const themeParams = JSON.parse(localStorage.getItem("pay.themeParams"))

	if (isIframe) {
		shellService.value = shallowReactive(new WebService());
		shellService.value.init({theme, themeParams});
	} else {
		shellService.value = shallowReactive(new TelegramService());
		shellService.value.init();
	}
	app.provide("shellService", shellService.value);
}
</script>

<style>
html[theme="light"] {
	--color-background: var(--theme_secondary_bg_color, #EFEEF4);
	--color-text: var(--theme_text_color, #060608);
	--color-text-hint: var(--theme_hint_color, #818181);
	--color-block: var(--theme_bg_color, rgb(255, 255, 255));
	--color-button: var(--theme_button_color, #007AFF);
	--color-button-text: var(--theme_button_text_color, white);

	--color-border: rgba(245, 245, 245, 0.3);
}

html[theme="dark"] {
	--color-background: var(--theme_secondary_bg_color, #000000);
	--color-text: var(--theme_text_color, white);
	--color-text-hint: var(--theme_hint_color, #98989A);
	--color-block: var(--theme_bg_color, #1C1C1C);
	--color-button: var(--theme_button_color, #3F87F6);
	--color-button-text: var(--theme_button_text_color, white);

	--color-border: rgba(15, 15, 15, 0.3);
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* text-align: center; */

	color: var(--color-text);
	background: var(--color-background);

	flex-grow: 1;
}

html,
body {
	height: 100%;
}

body {
	margin: 0;

	display: flex;
	flex-direction: column;
}
</style>
